import ApplicationController from "./application_controller";
import { useClickOutside } from "stimulus-use";

export default class extends ApplicationController {
  static targets = ["query", "dropdown", "searchIcon", "searchingIcon"];
  static values = {
    reflex: String,
  };

  connect() {
    super.connect();
    useClickOutside(this);
  }

  search(event) {
    event.preventDefault();
    this.searchIconTarget.hidden = true;
    this.searchingIconTarget.hidden = false;
    this.stimulate(`${this.reflexValue}#search`, this.queryTarget.value);
  }

  select(event) {
    event.preventDefault();
    const action = event.currentTarget.getAttribute("data-reflex-action");
    this.dropdownTarget.hidden = true;
    this.stimulate(`${this.reflexValue}#${action}`, event.currentTarget);
    this.queryTarget.focus();
  }

  clickOutside() {
    // clears the value from the query box and closes the dropdown
    this.queryTarget.value = this.queryTarget.getAttribute("value");
    if (this.hasDropdownTarget) {
      this.dropdownTarget.hidden = true;
    }
  }
}
