import { Controller } from "stimulus";
import { AddressFinder } from "@ideal-postcodes/address-finder";

export default class extends Controller {
  static values = {
    formScope: String,
    fieldName: String,
  };

  connect() {
    super.connect();

    const inputFieldId = `${this.formScopeValue}_${this.fieldNameValue}`;
    AddressFinder.setup({
      apiKey: this.api_key,
      detectCountry: false,
      defaultCountry: "GBR",
      restrictCountries: ["GBR"],
      // Encapsulates everything (including input)
      containerStyle: {
        fontSize: "0.875rem",
        color: "rgb(55 65 81 / 1)",
        fontWeight: "500",
        lineHeight: "1.25rem",
      },
      // Modifies the input field
      inputStyle: {},
      // Encapsulates toggleable Address Finder tool
      mainStyle: {
        borderRadius: "0.375rem",
      },
      // Main list element
      listStyle: {
        paddingTop: "0.5rem",
      },
      // Styles each suggestion item
      liStyle: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      outputFields: {
        line_1: `#${inputFieldId}_line_1`,
        line_2: `#${inputFieldId}_line_2`,
        line_3: `#${inputFieldId}_line_3`,
        post_town: `#${inputFieldId}_city`,
        postcode: `#${inputFieldId}_postcode`,
        uprn: `#${inputFieldId}_uprn`,
      },
    });
  }

  get api_key() {
    return document.head.querySelector("meta[name=ideal_postcodes_api_key]")
      .content;
  }
}
