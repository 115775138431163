import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";
import * as ActiveStorage from "@rails/activestorage";

import "css/application.css";
import debounced from "debounced";

import "chartkick/chart.js";

debounced.initialize();

Rails.start();
Turbolinks.start();
Turbolinks.setProgressBarDelay(200);
ActiveStorage.start();

import "controllers";

require("trix");
require("@rails/actiontext");

const images = require.context("../images", true);

var Trix = require("trix");
Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
};
const { lang } = Trix.config;

Trix.config.toolbar = {
  getDefaultHTML() {
    return `\
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
      </span>
      <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${lang.outdent}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${lang.indent}</button>
      </span>
    </div>
  \
`;
  },
};

// Increment this number to trigger a webpacker build on Heroku: 8
