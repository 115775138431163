import { Controller } from "stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.cleave = new Cleave(this.inputTarget, {
      date: true,
      datePattern: ["d", "m", "Y"],
    });
  }

  disconnect() {
    this.cleave.destroy();
  }
}
