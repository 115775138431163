import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "fileName"];

  select(e) {
    if(this.inputTarget.value) {
      this.fileNameTarget.textContent = this.inputTarget.value.split(/^.*[\\\/]/).slice(-1);
  }
  else {
    this.fileNameTarget.textContent = "No file chosen";
}
  }
}
