import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["main", "collapsedSidenav", "expandedSidenav"]

  connect() {
    const collapsed = localStorage.getItem('collapsed_sidenav')
    if (!(['true', 'false'].includes(collapsed))) {
      localStorage.setItem('collapsed_sidenav', 'false')
    }

    this.updateSideNavbar(localStorage.getItem('collapsed_sidenav') == 'true')
  }

  toggle(e) {
    const collapsed = !(localStorage.getItem('collapsed_sidenav') == 'true')
    localStorage.setItem("collapsed_sidenav", collapsed);

    this.updateSideNavbar(collapsed)
  }

  updateSideNavbar(collapsed) {
    if (collapsed) {
      this.mainTarget.classList.remove('lg:pl-72')
      this.mainTarget.classList.add('lg:pl-20')

      this.collapsedSidenavTarget.classList.remove('lg:hidden')
      this.expandedSidenavTarget.classList.add('lg:hidden')
    } else {
      this.mainTarget.classList.add('lg:pl-72')
      this.mainTarget.classList.remove('lg:pl-20')

      this.collapsedSidenavTarget.classList.add('lg:hidden')
      this.expandedSidenavTarget.classList.remove('lg:hidden')
    }
  }
}
